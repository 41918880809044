/* eslint-disable react/jsx-key */
import Link from 'next/link'
import Image from 'next/image';
import router from 'next/router'

import { AnimatePresence, motion } from 'framer-motion'
import { useSelector } from 'react-redux';
import { React, useState, useEffect, useCallback } from 'react'
import Autosearch from './autosearch'
import TopMenu from './topmenu'

function HeaderMenuItem({ menuTree, brandMenuTree, top_menu_tree, show_mobile_menu, autosearch }) {
    const useMediaQuery = (width) => {
        const [targetReached, setTargetReached] = useState(false);

        const updateTarget = useCallback((e) => {
            if (e.matches) {
                setTargetReached(true);
            } else {
                setTargetReached(false);
            }
        }, []);

        useEffect(() => {
            const media = window.matchMedia(`(max-width: ${width}px)`)
            media.addEventListener('change', e => updateTarget(e))

            // Check on mount (callback is not called until a change occurs)
            if (media.matches) {
                setTargetReached(true)
            }

            return () => media.removeEventListener('change', e => updateTarget(e))
        }, [updateTarget, width])

        return targetReached;
    };
    const is_mobile = useMediaQuery(1023)

    const menu_animations = {
        enter: {
            opacity: "1",
            x: 0,
            y: 0,
            transition: {
                duration: 1,
            },
        },
        hidden: {
            opacity: "0",
            x: 0,
            y: 0,
            transition: {
                duration: 1,
            },
        },
        exit: {
            opacity: "0",
            x: 0,
            y: 0,
            transition: {
                duration: 1,
            },
        }
    }

    const [top_level_menu, set_top_level_menu] = useState(false)
    const [second_level_menu, set_second_level_menu] = useState(false)
    const [brands_menu, set_brands_menu] = useState(false)
    const [merchantMenuTree, set_merchantMenuTree] = useState(false)
    const [merchant_menu, set_merchant_menu] = useState(false)

    const user = useSelector((state) => state.user);
    var token = false
    var merchant = false
    if (user[0] != undefined) {
        token = user[0].token
        if (user[0].roles != undefined && user[0].roles.findIndex((element) => element.name == "merchant") >= 0) {
            merchant = true
        }
    }

    const get_header_menu = (menu) => {
        // function to take all the menus and only extract the header one for rendering here
        var result = menu.filter(obj => {
            return obj.slug == 'header'
        })
        return result[0]
    }

    const get_link = (item) => {
        // we have a few different options in here

        // blog
        // product
        // taxonomy
        // this has different 'types'
        // landingpage
        // static link
        // other built in one

        // but basically there are 2 types:
        // one that has its url as the 'value' and one that has its url as item->value->url


        switch (item.type) {
            case "Blog":
            case "Product":
            case "Taxonomy":
            case "Page":
                return item.value.url
            case "static-url":
            case "text":
                return item.value
        }
    }

    const navigate = (item) => {
        var to = get_link(item)
        // turn the menus off
        set_brands_menu(false)
        set_top_level_menu(false)
        set_merchant_menu(false)
        // navigate
        router.push(to, undefined, { shallow: false })
    }

    const toggle_top_level_menu = (event, menu_id, type) => {

        // close brand menu if its open
        set_brands_menu(false)
        set_merchant_menu(false)

        // check if we have an open menu
        if (top_level_menu && top_level_menu.id && top_level_menu.id == menu_id && type != true) {
            set_top_level_menu(false)
        } else {

            // otherwise
            // take the id from teh function, check the main menu tree for that item
            var match = menuTree.menuItems.findIndex((element) => element.id == menu_id)

            if (match >= 0) {
                // save its children for use later
                set_top_level_menu(menuTree.menuItems[match])
            }
        }

    }

    const toggle_second_level_menu = (menu_id) => {
        // check if we have an open menu
        if (second_level_menu && second_level_menu.id && second_level_menu.id == menu_id) {
            set_second_level_menu(false)
        } else {

            // otherwise
            // take the id from teh function, check the main menu tree for that item
            var match = top_level_menu.children.findIndex((element) => element.id == menu_id)

            if (match >= 0) {
                // save its children for use later
                set_second_level_menu(top_level_menu.children[match])
            }
        }
    }

    const toggle_brands_menu = (menu_id) => {
        // close other menus if open
        set_top_level_menu(false)
        set_merchant_menu(false)
        // check if we have an open menu
        if (brands_menu !== false) {
            set_brands_menu(false)
        } else {
            // save its children for use later
            set_brands_menu(brandMenuTree)
        }
    }

    const toggle_merchant_menu = (menu_id) => {
        // close other menus if open
        set_top_level_menu(false)
        set_brands_menu(false)
        // check if we have an open menu
        if (merchant_menu !== false) {
            set_merchant_menu(false)
        } else {
            // take the id from teh function, check the main menu tree for that item
            var match = Object.keys(merchantMenuTree.menuItems)
            if (match >= 0) {
                // save its children for use later
                set_merchant_menu(merchantMenuTree.menuItems[match].children)
            }
        }
    }

    const get_column_count = (children) => {
        if (Number(children) <= 5) {
            return " lg:columns-1 "
        }
        if (Number(children) > 5 && Number(children) <= 10) {
            return "lg:columns-2 "
        }
        if (Number(children) > 10) {
            return " lg:columns-3 "
        }
    }

    const get_post_thumbnail = (media) => {
        // check the media for 'thumb' collection
        var match = media.findIndex((element) => element.collection_name == "thumb")
        if (match >= 0) {
            // take the thumbnail and return it
            return media[match].original_url
        } else {
            // if none, then return zeroth media element
            return media[0].original_url
        }
    }

    useEffect(() => {
        if (!router.isReady) return;
        // this one determines what kind of taxonomies we are checking against

        if (merchant) {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }

            // fetch serverside while rendering

            fetch(
                process.env.NEXT_PUBLIC_API_URL + '/menu/merchant/header',
                {
                    headers: headers,
                    method: 'POST'
                }
            ).then(response => {
                // reject not ok response
                if (!response.ok) {
                    return Promise.reject(response)
                }
                return response.json() // or return response.text()
            })
                .catch(async response => {
                    const error = await response.json().then(text => text)
                    return Promise.reject(error)
                })
                .then(data => {
                    // wrap this as an array as it sends an object back from the server
                    var menu = (get_header_menu([data]))
                    set_merchantMenuTree(menu)
                })
                .catch((error) => {
                    console.warn(error);
                });
        }

    }, [token, merchant]);

    return (
        <>
            {/* Mobile Menu */}
            <nav className={(show_mobile_menu) ? ("flex lg:flex lg:bg-ttd-sand-400 bg-white relative z-10") : ("hidden lg:flex lg:bg-ttd-sand-400 bg-white relative z-10")}>

                <div className="justify-between container container-2xl mx-auto flex flex-col lg:flex-row text-brand-tertiary font-medium border-t border-gray-300 lg:border-t-0 pb-6 lg:pb-0">
                    {(menuTree) ? (
                        // build the top level items only
                        menuTree.menuItems.map((menu) => (
                            (menu.children && menu.children.length > 0) ? (
                                <div key={menu.id}>
                                    <div className="">
                                        <button 
                                            type="button"
                                            onMouseEnter={(event) => toggle_top_level_menu(event, menu.id, true)}
                                            onMouseLeave={(event) => toggle_top_level_menu(event, menu.id, false)}
                                            className={
                                                (top_level_menu && top_level_menu.id == menu.id)
                                                ? 
                                                ("h-12 uppercase font-medium cursor-pointer transition-all flex lg:text-sm text-lg justify-between items-center leading-none lg:py-3 py-6 lg:px-4 px-5 lg:border-t-2 lg:border-brand-primary bg-ttd-sand-200 focus-visible:ring-4 focus-visible:ring-inset focus-visible:ring-brand-secondary") 
                                                : 
                                                ("h-12 uppercase font-medium cursor-pointer transition-all flex lg:text-sm text-lg justify-between items-center leading-none lg:py-3 py-6 lg:px-4 px-5 lg:border-t-2 active:bg-ttd-sand-200 lg:border-opacity-0 lg:border-ttd-khaki-300 lg:hover:border-opacity-100 focus-visible:ring-4 focus-visible:ring-inset focus-visible:ring-brand-secondary")
                                            }>
                                            {menu.name}
                                        </button>
                                    </div>
                                    {
                                        (top_level_menu && top_level_menu.id == menu.id) ? (
                                            // as this is now state-aware, we dont load it all in, we only load in the children of the selected item
                                            <AnimatePresence exitBeforeEnter>
                                                <motion.div
                                                    key={menu.id}
                                                    initial="hidden"
                                                    animate="enter" 
                                                    exit="exit"
                                                    variants={menu_animations}
                                                    onMouseEnter={(event) => toggle_top_level_menu(event, menu.id, true)}
                                                    onMouseLeave={(event) => toggle_top_level_menu(event, menu.id, false)}
                                                    className="bg-ttd-sand-200 w-full absolute left-0 right-0 lg:px-4 py-8 border-b-4 border-ttd-sand-400"
                                                >
                                                    <div className="container container-2xl mx-auto flex w-full">
                                                            
                                                            {top_level_menu.children.map((submenu) => (
                                                                <ul className="lg:w-1/6">
                                                                <li key={submenu.id}>
                                                                    <span onClick={() => navigate(submenu)}>
                                                                        <a className="text-ttd-grey-600 py-1 inline-block hover:underline active:text-brand-tertiary">{submenu.name}</a>
                                                                    </span>
                                                                </li>
                                                                
                                                                    {/* we need two loops here, this to make the second level items */}
                                                                        {submenu.children.map((childmenu) => (
                                                                            <li>
                                                                                <button onClick={() => navigate(childmenu)}>
                                                                                    <a className="text-ttd-grey-400 py-1 inline-block hover:underline active:text-brand-tertiary">{childmenu.name}</a>
                                                                                </button>
                                                                            </li>
                                                                        ))}
                                                                </ul>
                                                                ))}
                                                            

                                                            {(top_level_menu.value.media && top_level_menu.value.media[0] && top_level_menu.value.media[0].original_url) ? (
                                                                            <>
                                                                                <div className="lg:w-1/4 menu-image">
                                                                                    <Image
                                                                                    src={top_level_menu.value.media[0].original_url}
                                                                                    alt={top_level_menu.name}
                                                                                    width="180"
                                                                                    height="180"
                                                                                    className="mb-2 lg:inline-block"
                                                                                    placeholder="blur"

                                                                                    blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                                                                                />
                                                                                </div>    
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                            <div className="lg:w-1/4 menu-image">
                                                                                <div className="bg-gray-700 w-full h-60">
                                                                                <Image
                                                                                    src={process.env.NEXT_PUBLIC_COMING_SOON_IMAGE}
                                                                                    alt={top_level_menu.name}
                                                                                    height="180"
                                                                                    width="180"
                                                                                    className="mb-2 lg:inline-block"
                                                                                    placeholder="blur"

                                                                                    blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                                                                                />
                                                                                </div>
                                                                            </div>    
                                                                            </>
                                                                        )}
                                                  
                                                    </div>
                                                    <div className="container container-2xl mx-auto w-full">
                                                        <button 
                                                            onClick={() => navigate(top_level_menu)}
                                                            className="btn-arrow mt-4"
                                                        >
                                                            View All {top_level_menu.name}
                                                        </button>
                                                    </div>
                                                </motion.div>
                                            </AnimatePresence>
                                        ) : ('')
                                    }
                                </div>
                            ) : (
                                <div className="bg-white lg:bg-transparent lg:mr-4 border-b border-gray-300 lg:border-b-0 group" key={menu.id}>
                                    <span
                                        onClick={() => navigate(menu)}
                                        className="uppercase font-medium cursor-pointer transition-all flex lg:text-sm text-lg justify-between items-center leading-none lg:py-3 py-6 lg:px-4 px-5 lg:border-t-2 active:bg-ttd-sand-200 lg:border-opacity-0 lg:border-ttd-khaki-300 lg:hover:border-opacity-100 focus-visible:ring-4 focus-visible:ring-inset focus-visible:ring-brand-secondary">
                                        {menu.name}
                                    </span>
                                </div>
                            )
                        ))
                    ) : ('')
                    }
                    {(is_mobile) ? (
                        <>
                            {(token) ? (
                                <>
                                    <Link href="/account"><a className="btn btn-md btn-secondary m-5">My Account</a></Link>
                                    <Link href="/logout"><a className="btn btn-md btn-secondary ml-5 mr-5 mb-5">Logout</a></Link>
                                </>
                            ) : (
                                <>
                                    <Link href="/login"><a className=" btn btn-md btn-secondary m-5">Login</a></Link>
                                    <Link href="/login?create=true"><a className="btn btn-md btn-secondary  ml-5 mr-5 mb-5">Create Account</a></Link>
                                </>
                            )}
                            <TopMenu menu={top_menu_tree}></TopMenu>
                        </>
                    ) : ('')}
                </div>
                <Autosearch autosearch={autosearch}></Autosearch>
            </nav>
        </>
    );
}

export default HeaderMenuItem;