import { motion } from 'framer-motion'
import Link from 'next/link'
import Image from 'next/image'
import { React, useState, useEffect } from 'react'
import router from 'next/router'

function Autosearch({ autosearch }) {

    const menu_animations = {
        visible: {
            top: "3rem",
            transition: {
                x: {
                    duration: 1
                },
            }
        },
        hidden: {
            top: "-40rem",
            transition: {
                x: {
                    duration: 1
                },
            }
        },
    }

    const [close_autosearch_box, set_close_autosearch_box] = useState(false)

    useEffect(() => {
        if (autosearch.loaded == true) {
            set_close_autosearch_box(false)
        }
    }, [autosearch])

    const get_link = (item) => {
        // we have a few different options in here

        // blog
        // product
        // taxonomy
        // this has different 'types'
        // landingpage
        // static link
        // other built in one

        // but basically there are 2 types:
        // one that has its url as the 'value' and one that has its url as item->value->url


        switch (item.role) {
            case "merchant":
                return "/merchant/product/" + item.url
            default:
                return "/product/" + item.url
        }
    }


    const navigate = (item) => {
        var to = get_link(item)
        // turn the menus off
        set_close_autosearch_box(true)
        // navigate
        router.push(to, undefined, { shallow: false })
    }

    return (
        (autosearch.loaded == true && !close_autosearch_box) ? (
            <>
                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={menu_animations}
                    className="lg:absolute left-0 top-12 w-full bg-gray-50 lg:shadow-lg lg:block">
                    <div className="container container-2xl mx-auto lg:pt-8 lg:pb-3 px-5 pt-6 pb-3 relative">
                        <h4 className="font-bold text-lg text-gray-900 mb-4 lg:block">Search</h4>
                        <span onClick={() => set_close_autosearch_box(true)} className="absolute right-3 top-3"><i className="fas fa-times"></i></span>
                        <div className="grid lg:grid-cols-4 grid-cols-2 lg:gap-8 gap-6 lg:mb-12">

                            {autosearch.options.map(item => (
                                <>
                                    <div onClick={()=> navigate(item)} className="cursor-pointer">
                                        <div
                                            className="bg-white shadow rounded px-5 py-3 text-center border-2 border-white hover:border-brand-secondary active:border-brand-primary transition-all"
                                        // onClick={() => submit_search(item.name)}
                                        >

                                            {(item && item.media && item.media[0] && item.media[0].original_url) ? (
                                                <>
                                                    <Image
                                                        src={item.media[0].original_url}
                                                        alt={item.name}
                                                        width={process.env.NEXT_PUBLIC_SMALL_IMAGE_WIDTH}
                                                        height={process.env.NEXT_PUBLIC_SMALL_IMAGE_HEIGHT}
                                                        className="bg-white mb-4 inline-block object-contain"
                                                        placeholder="blur"

                                                        blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <Image
                                                        src={process.env.NEXT_PUBLIC_COMING_SOON_IMAGE}
                                                        alt={item.name}
                                                        height={process.env.NEXT_PUBLIC_SMALL_IMAGE_HEIGHT}
                                                        width={process.env.NEXT_PUBLIC_SMALL_IMAGE_WIDTH}
                                                        className="bg-white mb-4 inline-block object-contain"
                                                        placeholder="blur"

                                                        blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                                                    />
                                                </>
                                            )}
                                            <h6 className="font-medium text-base text-brand-primary">{item.name}</h6>
                                            <span className="font-medium text-xs text-gray-900">Advanced Water Code:</span><br />
                                            <span className="font-bold text-xs text-gray-700">
                                                {item.sku.toUpperCase().split(autosearch.query.toUpperCase())[0]}
                                                <strong>{autosearch.query.toUpperCase()}</strong>
                                                {item.sku.toUpperCase().split(autosearch.query.toUpperCase())[1]}
                                            </span>
                                            {/* {(item.featured == 1) ? (<i className="fa fa-jedi text-teal-500 ml-5"></i>) : ('')} */}
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                    <div className="text-center">
                        <Link href={"/search/" + autosearch.query}>
                            <a className="text-brand-primary text-base font-semibold block hover:bg-brand-primary hover:text-white py-3">View All</a>
                        </Link>
                    </div>
                </motion.div>
            </>
        ) : ('')
    )
}

export default Autosearch;