import Link from 'next/link'

import React from 'react'

function TopMenu({ menu }) {
    return (
        <>
            <ul className="flex flex-col lg:flex-row space-y-4">
                {(menu) ? ( 
                    menu.menuItems.map((item) => (
                        <li key={item.id} className="px-5">
                            <Link href={item.value}>
                                <a className="flex items-center text-brand-primary font-medium hover:underline" >
                                    {item.name}
                                </a>
                            </Link>
                        </li>
                    ))
                ) : ('')}
            </ul>
        </>
    );
}

export default TopMenu;