import Link from 'next/link'
import Image from 'next/image';
import router from 'next/router'

import { motion } from 'framer-motion'
import { useSelector,useDispatch } from 'react-redux';
import { toggleMenu } from '../../redux/menu.slice';
import { React, useState, useEffect, useCallback } from 'react'
import Autosearch from './autosearch'
import TopMenu from './topmenu'

function HeaderMenuItem({ menuTree, brandMenuTree, top_menu_tree, show_mobile_menu, autosearch }) {
    const useMediaQuery = (width) => {
        const [targetReached, setTargetReached] = useState(false);

        const updateTarget = useCallback((e) => {
            if (e.matches) {
                setTargetReached(true);
            } else {
                setTargetReached(false);
            }
        }, []);

        useEffect(() => {
            const media = window.matchMedia(`(max-width: ${width}px)`)
            media.addEventListener('change', e => updateTarget(e))

            // Check on mount (callback is not called until a change occurs)
            if (media.matches) {
                setTargetReached(true)
            }

            return () => media.removeEventListener('change', e => updateTarget(e))
        }, [updateTarget, width])

        return targetReached;
    };
    const is_mobile = useMediaQuery(1023)

    const menu_animations = {
        visible: {
            top: "3rem",
            transition: {
                x: {
                    duration: 1
                },
            }
        },
        hidden: {
            top: "-40rem",
            transition: {
                x: {
                    duration: 1
                },
            }
        },
    }

    const [top_level_menu, set_top_level_menu] = useState(false)
    const [second_level_menu, set_second_level_menu] = useState(false)
    const [brands_menu, set_brands_menu] = useState(false)
    const [merchantMenuTree, set_merchantMenuTree] = useState(false)
    const [merchant_menu, set_merchant_menu] = useState(false)
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);
    var token = false
    var merchant = false
    if (user[0] != undefined) {
        token = user[0].token
        if (user[0].roles != undefined && user[0].roles.findIndex((element) => element.name == "merchant") >= 0) {
            merchant = true
        }
    }

    const get_header_menu = (menu) => {
        // function to take all the menus and only extract the header one for rendering here
        var result = menu.filter(obj => {
            return obj.slug == 'header'
        })
        return result[0]
    }

    const get_link = (item) => {
        // we have a few different options in here

        // blog
        // product
        // taxonomy
        // this has different 'types'
        // landingpage
        // static link
        // other built in one

        // but basically there are 2 types:
        // one that has its url as the 'value' and one that has its url as item->value->url


        switch (item.type) {
            case "Blog":
            case "Product":
            case "Taxonomy":
            case "Page":
                return item.value.url
            case "static-url":
            case "text":
                return item.value
        }
    }

    const navigate = (item) => {
        var to = get_link(item)
        // turn the menus off
        set_brands_menu(false)
        set_top_level_menu(false)
        dispatch(toggleMenu())
        // navigate
        router.push(to, undefined, { shallow: false })
    }

    const toggle_top_level_menu = (menu_id) => {
        // close brand menu if its open
        set_brands_menu(false)
        set_merchant_menu(false)
        // check if we have an open menu
        if (top_level_menu && top_level_menu.id && top_level_menu.id == menu_id) {
            set_top_level_menu(false)
        } else {

            // otherwise
            // take the id from teh function, check the main menu tree for that item
            var match = menuTree.menuItems.findIndex((element) => element.id == menu_id)

            if (match >= 0) {
                // save its children for use later
                set_top_level_menu(menuTree.menuItems[match])
            }
        }
    }

    const toggle_second_level_menu = (menu_id) => {
        // check if we have an open menu
        if (second_level_menu && second_level_menu.id && second_level_menu.id == menu_id) {
            set_second_level_menu(false)
        } else {

            // otherwise
            // take the id from teh function, check the main menu tree for that item
            var match = top_level_menu.children.findIndex((element) => element.id == menu_id)

            if (match >= 0) {
                // save its children for use later
                set_second_level_menu(top_level_menu.children[match])
            }
        }
    }

    const toggle_brands_menu = (menu_id) => {
        // close other menus if open
        set_top_level_menu(false)
        set_merchant_menu(false)
        // check if we have an open menu
        if (brands_menu !== false) {
            set_brands_menu(false)
        } else {
            // save its children for use later
            set_brands_menu(brandMenuTree)
        }
    }
    const toggle_merchant_menu = (menu_id) => {
        // close other menus if open
        set_top_level_menu(false)
        set_brands_menu(false)
        // check if we have an open menu
        if (merchant_menu !== false) {
            set_merchant_menu(false)
        } else {
            // take the id from teh function, check the main menu tree for that item
            var match = Object.keys(merchantMenuTree.menuItems)
            if (match >= 0) {
                // save its children for use later
                set_merchant_menu(merchantMenuTree.menuItems[match].children)
            }
        }
    }

    const get_column_count = (children) => {
        if (Number(children) <= 5) {
            return " lg:columns-1 "
        }
        if (Number(children) > 5 && Number(children) <= 10) {
            return "lg:columns-2 "
        }
        if (Number(children) > 10) {
            return " lg:columns-3 "
        }
    }

    const get_post_thumbnail = (media) => {
        // check the media for 'thumb' collection
        var match = media.findIndex((element) => element.collection_name == "thumb")
        if (match >= 0) {
            // take the thumbnail and return it
            return media[match].original_url
        } else {
            // if none, then return zeroth media element
            return media[0].original_url
        }
    }

    useEffect(() => {
        if (!router.isReady) return;
        // this one determines what kind of taxonomies we are checking against

        if (merchant) {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }

            // fetch serverside while rendering

            fetch(
                process.env.NEXT_PUBLIC_API_URL + '/menu/merchant/header',
                {
                    headers: headers,
                    method: 'POST'
                }
            ).then(response => {
                // reject not ok response
                if (!response.ok) {
                    return Promise.reject(response)
                }
                return response.json() // or return response.text()
            })
                .catch(async response => {
                    const error = await response.json().then(text => text)
                    return Promise.reject(error)
                })
                .then(data => {
                    // wrap this as an array as it sends an object back from the server
                    var menu = (get_header_menu([data]))
                    set_merchantMenuTree(menu)
                })
                .catch((error) => {
                    console.warn(error);
                });
        }

    }, [token, merchant]);

    return (
        <>
            <nav className={(show_mobile_menu) ? ("w-full fixed top-12 mt-1 overflow-y-scroll border-b-4 border-brand-tertiary bottom-0 flex bg-white z-50") : ("hidden bg-white relative z-50")}>

                <div className="container container-2xl relative z-50 mx-auto flex flex-col lg:flex-row text-brand-primary font-medium border-t border-gray-300 pb-6 lg:pb-0">
                    {(menuTree) ? (
                        // build the top level items only
                        menuTree.menuItems.map((menu) => (
                            (menu.children && menu.children.length > 0) ? (
                                <div key={menu.id}>
                                    <div className="bg-white border-b border-gray-300 group">
                                        <button onClick={() => toggle_top_level_menu(menu.id)}
                                            className="text-brand-tertiary w-full font-medium transition-all lg:inline-block flex text-sm uppercase tracking-wider justify-between items-center leading-none py-4 px-5">
                                            {menu.name}
                                            {(top_level_menu && top_level_menu.id == menu.id) ? (
                                                <svg className="max-h-full h-4 w-4 stroke-current text-brand-tertiary stroke-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                    <g id="vuesax_linear_arrow-down" data-name="vuesax/linear/arrow-down" transform="translate(260 276) rotate(180)">
                                                    <g id="arrow-down">
                                                        <path id="Vector" d="M15.84,0,9.32,6.52a1.986,1.986,0,0,1-2.8,0L0,0" transform="translate(240.08 260.95)" fill="none" stroke="#747c61" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4"/>
                                                        <path id="Vector-2" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(260 276) rotate(180)" fill="none" opacity="0"/>
                                                    </g>
                                                    </g>
                                                </svg>
                                            ) : (
                                                <svg className="max-h-full h-4 w-4 stroke-current text-brand-tertiary stroke-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                    <g id="vuesax_linear_arrow-down" data-name="vuesax/linear/arrow-down" transform="translate(-236 -252)">
                                                        <g id="arrow-down">
                                                        <path id="Vector" d="M15.84,0,9.32,6.52a1.986,1.986,0,0,1-2.8,0L0,0" transform="translate(240.08 260.95)" fill="none" stroke="#747c61" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4"/>
                                                        <path id="Vector-2" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(260 276) rotate(180)" fill="none" opacity="0"/>
                                                        </g>
                                                    </g>
                                                </svg>
                                            )}
                                        </button>
                                    </div>
                                    {
                                        (top_level_menu && top_level_menu.id == menu.id) ? (
                                            // as this is now state-aware, we dont load it all in, we only load in the children of the selected item
                                            <motion.div
                                                initial="hidden"
                                                animate="visible"
                                                variants={menu_animations}
                                                className="left-0 top-40 w-full menu-dropdown-bg-override group-hover:opacity-100 z-50"
                                            >
                                                <div className="container container-2xl mx-auto font-medium">
                                                    <div className="bg-ttd-sand-200 flex text-sm text-brand-tertiary w-full relative">
                                                        <ul className="w-full relative">
                                                            {/* we need two loops here, this to make the second level items, one to make the third */}
                                                            {top_level_menu.children.map((submenu) => (
                                                                <li key={submenu.id} className="border-b border-sttd-sand-400">
                                                                    <a
                                                                        onClick={() => toggle_second_level_menu(submenu.id)}
                                                                        className="text-brand-tertiary flex items-center justify-between relative px-5 py-4 group">
                                                                        {submenu.name}
                                                                        {(second_level_menu && second_level_menu.id == submenu.id) ? (
                                                                            <svg className="max-h-full h-4 w-4 stroke-current text-brand-tertiary stroke-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                                <g id="vuesax_linear_arrow-down" data-name="vuesax/linear/arrow-down" transform="translate(260 276) rotate(180)">
                                                                                <g id="arrow-down">
                                                                                    <path id="Vector" d="M15.84,0,9.32,6.52a1.986,1.986,0,0,1-2.8,0L0,0" transform="translate(240.08 260.95)" fill="none" stroke="#747c61" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4"/>
                                                                                    <path id="Vector-2" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(260 276) rotate(180)" fill="none" opacity="0"/>
                                                                                </g>
                                                                                </g>
                                                                            </svg>
                                                                        ) : (
                                                                            <svg className="max-h-full h-4 w-4 stroke-current text-brand-tertiary stroke-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                                <g id="vuesax_linear_arrow-down" data-name="vuesax/linear/arrow-down" transform="translate(-236 -252)">
                                                                                    <g id="arrow-down">
                                                                                    <path id="Vector" d="M15.84,0,9.32,6.52a1.986,1.986,0,0,1-2.8,0L0,0" transform="translate(240.08 260.95)" fill="none" stroke="#747c61" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4"/>
                                                                                    <path id="Vector-2" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(260 276) rotate(180)" fill="none" opacity="0"/>
                                                                                    </g>
                                                                                </g>
                                                                            </svg>
                                                                        )}
                                                                    </a>

                                                                    {(second_level_menu && second_level_menu.id == submenu.id) ? (
                                                                        <div className="">
                                                                            {/* we need two loops here, one to make the second level items, this to make the third */}

                                                                            {(second_level_menu && second_level_menu.children) ? (
                                                                                <div>
                                                                                    <ul className={get_column_count(second_level_menu.children.length) + "px-5"}>
                                                                                        {second_level_menu.children.map((bottommenu) => (
                                                                                            <li className="mb-3" key={bottommenu.id} >
                                                                                                <a
                                                                                                    onClick={() => navigate(bottommenu)}
                                                                                                    className="text-brand-tertiary flex items-center py-2">
                                                                                                    {bottommenu.name}
                                                                                                </a>
                                                                                            </li>
                                                                                        ))}
                                                                                    </ul>
                                                                                    <a
                                                                                        onClick={() => navigate(second_level_menu)}
                                                                                        className="px-5 flex pt-2 pb-4 text-center text-sm text-brand-tertiary"
                                                                                    >
                                                                                        View All {second_level_menu.name}
                                                                                    </a>
                                                                                </div>
                                                                            ) : ('')}
                                                                        </div>
                                                                    ) : (
                                                                        ''
                                                                    )}


                                                                </li>
                                                            ))}
                                                        </ul>

                                                    </div>
                                                </div>
                                                <div className="bg-ttd-sand-400 text-center">
                                                    <a
                                                        onClick={() => navigate(top_level_menu)}
                                                        className="text-brand-tertiary text-sm font-medium block py-3"
                                                    >
                                                        View All {top_level_menu.name}
                                                    </a>
                                                </div>
                                            </motion.div>
                                        ) : ('')
                                    }
                                </div>
                            ) : (
                                <div className="bg-white lg:bg-transparent lg:mr-4 border-b border-gray-300 lg:border-b-0 group" key={menu.id}>
                                    <span
                                        onClick={() => navigate(menu)}
                                        className="text-brand-tertiary font-medium cursor-pointer transition-all lg:inline-block flex lg:text-base text-sm uppercase tracking-wider justify-between items-center leading-none lg:py-3 py-4 lg:px-4 px-5 lg:hover:bg-brand-primary lg:active:bg-brand-primary focus-visible:ring-4 focus-visible:ring-inset focus-visible:ring-brand-secondary">
                                        {menu.name}
                                    </span>
                                </div>
                            )
                        ))
                    ) : ('')
                    }

                    {/* {
                        (brandMenuTree && brandMenuTree.menuItems) ? (
                            <div key={brandMenuTree.id}>
                                <div className="bg-white lg:bg-transparent lg:mr-4 border-b border-gray-300 lg:border-b-0">
                                    <button 
                                        type="button"
                                        onClick={() => toggle_brands_menu(brandMenuTree.id)}
                                        className="text-brand-tertiary w-full font-medium cursor-pointer transition-all lg:inline-block flex lg:text-base text-sm uppercase tracking-wider justify-between items-center leading-none lg:py-3 py-6 lg:px-4 px-5">
                                        Brands
                                        {(brands_menu && brands_menu.id == brandMenuTree.id) ? (
                                            <i className="fas fa-angle-up text-brand-primary lg:text-white cursor-pointer ml-3"></i>
                                        ) : (
                                            <i className="fas fa-angle-down text-brand-primary hover:text-white hover:cursor-pointer ml-3"></i>
                                        )}
                                    </button>
                                    {
                                        (brands_menu && brands_menu.id == brandMenuTree.id) ? (
                                            <motion.div
                                                initial="hidden"
                                                animate="visible"
                                                variants={menu_animations}
                                                className="lg:absolute left-0 top-12 w-full bg-gray-50 lg:shadow-lg lg:block">
                                                <div className="container container-2xl mx-auto lg:pt-8 lg:pb-3 px-5 pt-6 pb-3">
                                                    <h4 className="h4 mb-4">Popular Brands</h4>
                                                    <div className="grid lg:grid-cols-5 grid-cols-2 lg:gap-8 gap-6 lg:mb-12">
                                                        {brands_menu.menuItems.map((menu) => (
                                                            <div
                                                                key={menu.id}
                                                                onClick={() => navigate(menu)}
                                                                className="cursor-pointer bg-white shadow rounded px-5 py-3 text-center border-2 border-white hover:border-brand-secondary active:border-brand-primary transition-all">
                                                                {(menu.value.media && menu.value.media[0] && menu.value.media[0].original_url) ? (
                                                                    <>
                                                                        <Image
                                                                            src={menu.value.media[0].original_url}
                                                                            alt={menu.name}
                                                                            width={process.env.NEXT_PUBLIC_SMALL_IMAGE_WIDTH}
                                                                            height={process.env.NEXT_PUBLIC_SMALL_IMAGE_HEIGHT}
                                                                            className="bg-white mb-4 inline-block object-contain"
                                                                            placeholder="blur"

                                                                            blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <Image
                                                                            src={process.env.NEXT_PUBLIC_COMING_SOON_IMAGE}
                                                                            alt={menu.name}
                                                                            height={process.env.NEXT_PUBLIC_SMALL_IMAGE_HEIGHT}
                                                                            width={process.env.NEXT_PUBLIC_SMALL_IMAGE_WIDTH}
                                                                            className="bg-white mb-4 inline-block object-contain"
                                                                            placeholder="blur"

                                                                            blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                                                                        />
                                                                    </>
                                                                )}
                                                                <h6 className="font-medium text-base text-brand-primary">{menu.name}</h6>
                                                            </div>
                                                        ))
                                                        }
                                                    </div>
                                                </div>
                                                <div className="text-center">
                                                    <Link href="/brands">
                                                        <a className="text-brand-primary bg-white text-base font-medium block hover:bg-brand-primary hover:text-white py-3 transition duration-150">View All Brands</a>
                                                    </Link>
                                                </div>
                                            </motion.div>
                                        ) : ('')
                                    }
                                </div>
                            </div>
                        ) : ('')
                    } */}
                    
                    <div className="lg:justify-self-end bg-white lg:bg-transparent lg:ml-auto border-b border-gray-300 lg:border-b-0 group" key="ContactUs">
                        <Link href="/contact">
                            <a
                                className="text-brand-tertiary font-medium cursor-pointer transition-all lg:inline-block flex lg:text-base text-sm uppercase tracking-wider justify-between items-center leading-none lg:py-3 py-4 lg:px-4 px-5 lg:hover:bg-brand-primary lg:active:bg-brand-primary focus-visible:ring-4 focus-visible:ring-inset focus-visible:ring-brand-secondary">
                                Contact Us
                            </a>
                        </Link>
                    </div>

                    {(is_mobile) ? (
                        <>
                            {(token) ? (
                                <>
                                    <Link href="/account"><a className="btn btn-md btn-primary mt-6 mb-2 mx-5">My Account</a></Link>
                                    <Link href="/logout"><a className="btn btn-md btn-secondary mx-5">Logout</a></Link>
                                </>
                            ) : (
                                <>
                                    <Link href="/login"><a className=" btn btn-md btn-primary mt-6 mb-2 mx-5">Login</a></Link>
                                    <Link href="/login?create=true"><a className="btn btn-md btn-secondary mx-5">Create Account</a></Link>
                                </>
                            )}
                            <div className="mt-8 pb-8">
                                <TopMenu menu={top_menu_tree}></TopMenu>
                            </div>
                        </>
                    ) : ('')}


                </div>
                <Autosearch autosearch={autosearch}></Autosearch>
            </nav>
        </>
    );
}

export default HeaderMenuItem;